var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "RealTimeScheduling" },
    [
      _c("div", { staticClass: "top-search" }, [
        _c(
          "div",
          { staticClass: "top-search" },
          [
            _c(
              "el-select",
              {
                staticClass: "select",
                attrs: { filterable: "", placeholder: "请选择或搜索线路" },
                on: { change: _vm.changeLine },
                model: {
                  value: _vm.lineCode,
                  callback: function($$v) {
                    _vm.lineCode = $$v
                  },
                  expression: "lineCode"
                }
              },
              _vm._l(_vm.lineOptions, function(item) {
                return _c("el-option", {
                  key: item.companyId,
                  attrs: { label: item.companyName, value: item.companyId }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "simulation-map-box" }, [
        _c("div", { staticClass: "simulation-map-between left" }, [
          _c("div", { staticClass: "title" }, [_vm._v(" 主场 ")]),
          _c(
            "div",
            { staticClass: "main-line" },
            _vm._l(_vm.dispatchPlanList.masterPlan, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "list-car" },
                [
                  _c("i", { class: _vm.getBusIcon(item.natureState) }),
                  _c(
                    "el-popover",
                    { attrs: { placement: "right", trigger: "click" } },
                    [
                      _c("div", { staticClass: "inner-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: { click: _vm.sendMessage }
                          },
                          [_vm._v(" 消息下发 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function($event) {
                                return _vm.sendPlanText($event, item.id)
                              }
                            }
                          },
                          [_vm._v(" 下发行车计划 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function($event) {
                                return _vm.changeDriverPop(item)
                              }
                            }
                          },
                          [_vm._v(" 调整计划 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function($event) {
                                return _vm.manualStart(item)
                              }
                            }
                          },
                          [_vm._v(" 手动发车 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function($event) {
                                return _vm.changeStatus(item)
                              }
                            }
                          },
                          [_vm._v(" 修改运行状态 ")]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "cph",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(item.cph.slice(2)))]
                      )
                    ]
                  ),
                  _c("span", { class: item.overTime ? "overtime" : "time" }, [
                    _vm._v(_vm._s(item.beginTime))
                  ])
                ],
                1
              )
            }),
            0
          )
        ]),
        _c("div", { staticClass: "simulation-map-center" }, [
          _c("div", { staticClass: "center-logo" }, [
            _c("div", { staticClass: "online" }, [
              _c("i", {
                staticClass: "el-icon-question",
                staticStyle: { color: "#C8C9CC" }
              }),
              _vm._v(" 在线/运行/配车: "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.dispatchStatisticsData.online) +
                    "/" +
                    _vm._s(_vm.dispatchStatisticsData.operate) +
                    "/" +
                    _vm._s(_vm.dispatchStatisticsData.matching)
                )
              ])
            ]),
            _c("div", { staticClass: "name" }, [
              _vm._v(" " + _vm._s(_vm.activeName) + " ")
            ]),
            _c(
              "div",
              { staticClass: "logo" },
              _vm._l(_vm.hintOptions, function(item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  _c("img", { attrs: { src: item.url, alt: "" } }),
                  _c("span", [_vm._v(_vm._s(item.val))])
                ])
              }),
              0
            )
          ]),
          _c("div", { staticClass: "simulation-map" }, [
            _c("div", { staticClass: "up" }, [
              _c("div", { staticClass: "line-center" }),
              _c(
                "ul",
                { ref: "upUl" },
                [
                  _vm._l(_vm.zhuTofuOptions, function(item) {
                    return _c("li", { key: item.stationNo }, [
                      _c("i"),
                      _c("span", [_vm._v(_vm._s(item.stationName))])
                    ])
                  }),
                  _c("div", { staticClass: "bus-up" }, [
                    _c(
                      "div",
                      { ref: "up", staticClass: "line" },
                      _vm._l(_vm.downBus, function(bus, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "cus-dropdown",
                            style:
                              "left:" +
                              _vm.upStationSpace *
                                (bus.stationNo -
                                  1 +
                                  (bus.arriveType == "1" ? 0 : 1) * 0.5) +
                              "px",
                            attrs: {
                              "staion-num": bus.stationNo,
                              "arrive-type": bus.arriveType
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "el-dropdown-link",
                                on: { click: _vm.onDropDownClick }
                              },
                              [
                                _c(
                                  "el-badge",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      value: bus.busCodes.length,
                                      hidden: bus.busCodes.length == 1
                                    }
                                  },
                                  [
                                    _c("i", {
                                      class: _vm.getBusIcon(
                                        bus.busCodes[0].status
                                      )
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "ul",
                              {
                                staticClass: "dropdown-list dropdown-down",
                                style: { zIndex: _vm.selfPage ? "9999" : "100" }
                              },
                              _vm._l(bus.busCodes, function(code) {
                                return _c(
                                  "li",
                                  {
                                    key: code.busCodes,
                                    attrs: { vehicleNo: code.vehicleNo }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(code.busCode.slice(2)) + " "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ],
                2
              )
            ]),
            _c("div", { staticClass: "down" }, [
              _c("div", { staticClass: "line-center" }),
              _c(
                "ul",
                { ref: "downUl" },
                [
                  _vm._l(_vm.fuToZhuOptions, function(item) {
                    return _c("li", { key: item.stationNo }, [
                      _c("i"),
                      _c("span", [_vm._v(_vm._s(item.stationName))])
                    ])
                  }),
                  _c("div", { staticClass: "bus-down" }, [
                    _c(
                      "div",
                      { ref: "down", staticClass: "line" },
                      _vm._l(_vm.upBus, function(bus, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "cus-dropdown",
                            style:
                              "left:" +
                              (_vm.ulWidth -
                                22 -
                                _vm.downStationSpace *
                                  (bus.stationNo -
                                    1 +
                                    (bus.arriveType == "1" ? 0 : 1) * 0.5) +
                                1) +
                              "px",
                            attrs: {
                              "staion-num": bus.stationNo,
                              "arrive-type": bus.arriveType
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "el-dropdown-link",
                                on: { click: _vm.onDropDownClick }
                              },
                              [
                                _c(
                                  "el-badge",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      value: bus.busCodes.length,
                                      hidden: bus.busCodes.length == 1
                                    }
                                  },
                                  [
                                    _c("i", {
                                      class: _vm.getBusIcon(
                                        bus.busCodes[0].status
                                      )
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "ul",
                              {
                                staticClass: "dropdown-list dropdown-up",
                                style: { zIndex: _vm.selfPage ? "9999" : "100" }
                              },
                              _vm._l(bus.busCodes, function(code) {
                                return _c(
                                  "li",
                                  {
                                    key: code.busCodes,
                                    attrs: { vehicleNo: code.vehicleNo }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(code.busCode.slice(2)) + " "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ],
                2
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "map-notOperateList" },
            [
              _vm._v(" 其他任务车辆: "),
              _vm._l(_vm.dispatchStatisticsData.notOperateList, function(
                ele,
                index
              ) {
                return _c(
                  "div",
                  { key: index, staticClass: "other-cars" },
                  [
                    _c(
                      "el-popover",
                      { attrs: { placement: "right", trigger: "click" } },
                      [
                        _c("div", { staticClass: "inner-box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.changeStatus(ele)
                                }
                              }
                            },
                            [_vm._v(" 修改运行状态 ")]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "cph",
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          [_vm._v(_vm._s(ele.cph.slice(2)))]
                        )
                      ]
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ]),
        _c("div", { staticClass: "simulation-map-between right" }, [
          _c("div", { staticClass: "title" }, [_vm._v(" 副场 ")]),
          _c(
            "div",
            { staticClass: "main-line" },
            _vm._l(_vm.dispatchPlanList.slaverPlan, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "list-car" },
                [
                  _c("i", { class: _vm.getBusIcon(item.natureState) }),
                  _c(
                    "el-popover",
                    { attrs: { placement: "left", trigger: "click" } },
                    [
                      _c("div", { staticClass: "inner-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: { click: _vm.sendMessage }
                          },
                          [_vm._v(" 消息下发 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function($event) {
                                return _vm.sendPlanText($event, item.id)
                              }
                            }
                          },
                          [_vm._v(" 下发行车计划 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function($event) {
                                return _vm.changeDriverPop(item)
                              }
                            }
                          },
                          [_vm._v(" 调整计划 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function($event) {
                                return _vm.manualStart(item)
                              }
                            }
                          },
                          [_vm._v(" 手动发车 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function($event) {
                                return _vm.changeStatus(item)
                              }
                            }
                          },
                          [_vm._v(" 修改运行状态 ")]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "cph",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(item.cph.slice(2)))]
                      )
                    ]
                  ),
                  _c("span", { class: item.overTime ? "overtime" : "time" }, [
                    _vm._v(_vm._s(item.beginTime))
                  ])
                ],
                1
              )
            }),
            0
          )
        ])
      ]),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c(
              "div",
              { staticClass: "grid-content" },
              [
                _c(
                  "div",
                  { staticClass: "top-content" },
                  [
                    _c("span", { staticStyle: { "margin-right": "24px" } }, [
                      _vm._v("待发车列表")
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "small" },
                        on: { click: _vm.sendMessage }
                      },
                      [_vm._v("消息下发")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "small" },
                        on: { click: _vm.sendPlanText }
                      },
                      [_vm._v("下发计划")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "small" },
                        on: { click: _vm.changeDriver }
                      },
                      [_vm._v("调整计划")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "small" },
                        on: {
                          click: function() {
                            return (_vm.addShiftOrTripDia = true)
                          }
                        }
                      },
                      [_vm._v("增加计划")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "small" },
                        on: { click: _vm.manualStartTogether }
                      },
                      [_vm._v("手动发车")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "small" },
                        on: { click: _vm.changeStatusSelect }
                      },
                      [_vm._v("修改状态")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "basicBox dispatchMonitoring",
                    attrs: {
                      data: _vm.startPlanDetailList,
                      height: "calc(100vh - 47vh - 222px)",
                      stripe: "",
                      "cell-style": { color: "#000", fontSize: "16px" },
                      "header-cell-style": {
                        background: "#FAFAFA",
                        color: "#999999"
                      }
                    },
                    on: { "selection-change": _vm.getCheckedData }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        selectable: _vm.checkboxT,
                        disabled: "true"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "cph", label: "车辆" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "column-color",
                                  on: {
                                    click: function($event) {
                                      return _vm.showBusPlan(scope.row)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.cph.slice(2)))]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "realityDriverName", label: "驾驶员" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "endTime", label: "到达时间" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "beginTime", label: "发车时间" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "natureState", label: "状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("filterNatureState")(
                                      scope.row.natureState
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c(
              "div",
              { staticClass: "grid-content" },
              [
                _c(
                  "el-tabs",
                  { attrs: { type: "border-card" } },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "消息列表" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "basicBox dispatchMonitoring",
                            attrs: {
                              data: _vm.messageList,
                              height: "calc(100vh - 47vh - 237px)",
                              stripe: "",
                              "cell-style": { color: "#000", fontSize: "16px" },
                              "header-cell-style": {
                                background: "#FAFAFA",
                                color: "#999999"
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "排序",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "cph", label: "车辆" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F48B41" } },
                                        [_vm._v(_vm._s(scope.row.cph.slice(2)))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "线路", prop: "companyName" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "content",
                                label: "消息内容",
                                width: "180",
                                "show-overflow-tooltip": true
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "type", label: "消息类型" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "createTime",
                                label: "时间",
                                width: "180"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "status", label: "状态" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("filterStatus")(
                                              scope.row.status
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "到离站信息" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "basicBox dispatchMonitoring",
                            attrs: {
                              data: _vm.arriveAndLeaveStationList,
                              height: "calc(100vh - 47vh - 237px)",
                              stripe: "",
                              "cell-style": { color: "#000", fontSize: "16px" },
                              "header-cell-style": {
                                background: "#FAFAFA",
                                color: "#999999"
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "排序",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "cph", label: "车辆" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F48B41" } },
                                        [_vm._v(_vm._s(scope.row.cph.slice(2)))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "线路", prop: "companyName" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "businessType", label: "方向" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.businessType === 1
                                              ? "上行"
                                              : "下行"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "gpsTime",
                                label: "时间",
                                width: "180"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "arriveType", label: "类型" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.arriveType === 1
                                              ? "到站"
                                              : "离站"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "stationName",
                                label: "站点名称",
                                width: "160",
                                "show-overflow-tooltip": true
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "realityDriverName",
                                label: "当班司机"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "车辆请求" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "basicBox dispatchMonitoring",
                            attrs: {
                              data: _vm.requestlist,
                              height: "calc(100vh - 47vh - 237px)",
                              stripe: "",
                              "cell-style": { color: "#000", fontSize: "16px" },
                              "header-cell-style": {
                                background: "#FAFAFA",
                                color: "#999999"
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "排序",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "cph", label: "车辆" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F48B41" } },
                                        [_vm._v(_vm._s(scope.row.cph.slice(2)))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "线路", prop: "companyName" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "driverName", label: "司机" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "eventId",
                                label: "消息内容",
                                width: "120"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.natureOptions.find(function(t) {
                                            return (
                                              t.dictCode == scope.row.eventId
                                            )
                                          }).dictValue
                                        )
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "insertTime",
                                label: "时间",
                                width: "180"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "opStats",
                                label: "状态",
                                width: "120"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("filteropStats")(
                                              scope.row.opStats
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "action",
                                label: "操作",
                                width: "120"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("div", { staticClass: "action" }, [
                                        scope.row.opStats !== 1 &&
                                        scope.row.opStats !== 2
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "agree",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.agreeAndReject(
                                                      scope.row.id,
                                                      1
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("同意")]
                                            )
                                          : _vm._e(),
                                        scope.row.opStats !== 1 &&
                                        scope.row.opStats !== 2
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "reject",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.agreeAndReject(
                                                      scope.row.id,
                                                      2
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("拒绝")]
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("sendMsg", {
                attrs: { lineId: _vm.lineCode },
                on: {
                  closeDia: function() {
                    return (_vm.dialogVisible = false)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.driverdialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "计划调整",
                visible: _vm.driverdialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.driverdialogVisible = $event
                }
              }
            },
            [
              _c("driverPlan", {
                attrs: {
                  updateform: _vm.updateform,
                  nowBusByline: _vm.nowBusByline,
                  nowDriverByline: _vm.nowDriverByline
                },
                on: {
                  closeDia: function() {
                    return (_vm.driverdialogVisible = false)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.schedulingVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "单车发班表",
                visible: _vm.schedulingVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.schedulingVisible = $event
                }
              }
            },
            [
              _c("schedulingPlan", {
                attrs: {
                  busPlanList: _vm.busPlanList,
                  busCode: _vm.busCode,
                  banCiMoveType: _vm.banCiMoveType
                },
                on: {
                  closeDia: function() {
                    return (_vm.schedulingVisible = false)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "addShiftOrTripDia",
          attrs: {
            title: "添加班次/车次",
            visible: _vm.addShiftOrTripDia,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.addShiftOrTripDia = $event
            },
            close: _vm.cancleAddShiftOrTripDia,
            open: function($event) {
              _vm.addShiftOrTripDia = true
            }
          }
        },
        [
          _c("addShiftOrTrip", {
            ref: "addShiftOrTripRefs",
            on: {
              closeDia: function() {
                return (_vm.addShiftOrTripDia = false)
              }
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "addShiftOrTripDia",
          attrs: {
            title: "修改车辆状态",
            visible: _vm.changeStatusVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.changeStatusVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "statusForm",
              staticClass: "demo-form dialog-form",
              attrs: {
                model: _vm.statusForm,
                "label-width": "102px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "车次状态：", prop: "state" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择车次状态" },
                      model: {
                        value: _vm.statusForm.state,
                        callback: function($$v) {
                          _vm.$set(_vm.statusForm, "state", $$v)
                        },
                        expression: "statusForm.state"
                      }
                    },
                    _vm._l(_vm.natureOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: {
                          label: item.dictValue,
                          value: Number(item.dictCode)
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.changeStatusVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveStatus }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialogTableVisible
        ? _c("car-dialog", {
            attrs: {
              titlex: _vm.plate,
              id: _vm.plate,
              area: _vm.area,
              street: _vm.street,
              checkObj: _vm.carDetailObj
            },
            on: {
              dialogVisible: function() {
                return (_vm.dialogTableVisible = false)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }