<template>
  <div class="RealTimeScheduling">
    <!-- 顶部搜索框 -->
    <div class="top-search">
      <div class="top-search">
        <el-select
          filterable
          @change="changeLine"
          v-model="lineCode"
          placeholder="请选择或搜索线路"
          class="select"
        >
          <el-option
            v-for="item in lineOptions"
            :key="item.companyId"
            :label="item.companyName"
            :value="item.companyId"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- 模拟地图 -->
    <div class="simulation-map-box">
      <div class="simulation-map-between left">
        <div class="title">
          主场
        </div>
        <div class="main-line">
          <div
            v-for="(item, index) in dispatchPlanList.masterPlan"
            :key="index"
            class="list-car"
          >
            <i :class="getBusIcon(item.natureState)"></i>
            <el-popover placement="right" trigger="click">
              <div class="inner-box">
                <div class="inner-item" @click="sendMessage">
                  消息下发
                </div>
                <div class="inner-item" @click="sendPlanText($event, item.id)">
                  下发行车计划
                </div>
                <div class="inner-item" @click="changeDriverPop(item)">
                  调整计划
                </div>
                <div class="inner-item" @click="manualStart(item)">
                  手动发车
                </div>
                <div class="inner-item" @click="changeStatus(item)">
                  修改运行状态
                </div>
              </div>
              <div slot="reference" class="cph">{{ item.cph.slice(2) }}</div>
            </el-popover>
            <span :class="item.overTime ? 'overtime' : 'time'">{{
              item.beginTime
            }}</span>
          </div>
        </div>
      </div>
      <div class="simulation-map-center">
        <div class="center-logo">
          <div class="online">
            <i class="el-icon-question" style="color:#C8C9CC;"></i>
            在线/运行/配车:
            <span
              >{{ dispatchStatisticsData.online }}/{{
                dispatchStatisticsData.operate
              }}/{{ dispatchStatisticsData.matching }}</span
            >
          </div>
          <div class="name">
            {{ activeName }}
          </div>
          <div class="logo">
            <div v-for="(item, index) in hintOptions" :key="index" class="item">
              <img :src="item.url" alt="" />
              <span>{{ item.val }}</span>
            </div>
          </div>
        </div>
        <div class="simulation-map">
          <!-- 下行- 主到付 -->
          <div class="up">
            <div class="line-center"></div>
            <ul ref="upUl">
              <li v-for="item in zhuTofuOptions" :key="item.stationNo">
                <i></i>
                <span>{{ item.stationName }}</span>
              </li>
              <div class="bus-up">
                <div class="line" ref="up">
                  <div
                    class="cus-dropdown"
                    v-for="(bus, index) in downBus"
                    :key="index"
                    :staion-num="bus.stationNo"
                    :arrive-type="bus.arriveType"
                    :style="
                      'left:' +
                        upStationSpace *
                          (bus.stationNo -
                            1 +
                            (bus.arriveType == '1' ? 0 : 1) * 0.5) +
                        'px'
                    "
                  >
                    <span class="el-dropdown-link" @click="onDropDownClick">
                      <el-badge
                        :value="bus.busCodes.length"
                        class="item"
                        :hidden="bus.busCodes.length == 1"
                      >
                        <i :class="getBusIcon(bus.busCodes[0].status)"></i>
                      </el-badge>
                    </span>
                    <ul
                      class="dropdown-list dropdown-down"
                      :style="{ zIndex: selfPage ? '9999' : '100' }"
                    >
                      <li
                        v-for="code in bus.busCodes"
                        :key="code.busCodes"
                        :vehicleNo="code.vehicleNo"
                      >
                        {{ code.busCode.slice(2) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <!-- 下线 -->
          <div class="down">
            <div class="line-center"></div>
            <ul ref="downUl">
              <li v-for="item in fuToZhuOptions" :key="item.stationNo">
                <i></i>
                <span>{{ item.stationName }}</span>
              </li>
              <div class="bus-down">
                <div class="line" ref="down">
                  <div
                    class="cus-dropdown"
                    v-for="(bus, index) in upBus"
                    :key="index"
                    :staion-num="bus.stationNo"
                    :arrive-type="bus.arriveType"
                    :style="
                      'left:' +
                        (ulWidth -
                          22 -
                          downStationSpace *
                            (bus.stationNo -
                              1 +
                              (bus.arriveType == '1' ? 0 : 1) * 0.5) +
                          1) +
                        'px'
                    "
                  >
                    <span class="el-dropdown-link" @click="onDropDownClick">
                      <el-badge
                        :value="bus.busCodes.length"
                        class="item"
                        :hidden="bus.busCodes.length == 1"
                      >
                        <i :class="getBusIcon(bus.busCodes[0].status)"></i>
                      </el-badge>
                    </span>
                    <ul
                      class="dropdown-list dropdown-up"
                      :style="{ zIndex: selfPage ? '9999' : '100' }"
                    >
                      <li
                        v-for="code in bus.busCodes"
                        :key="code.busCodes"
                        :vehicleNo="code.vehicleNo"
                      >
                        {{ code.busCode.slice(2) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="map-notOperateList">
          其他任务车辆:
          <!-- <span v-for="(ele,index) in dispatchStatisticsData.notOperateList" :key="index">{{ele.cph}}</span> -->
          <div
            v-for="(ele, index) in dispatchStatisticsData.notOperateList"
            :key="index"
            class="other-cars"
          >
            <el-popover placement="right" trigger="click">
              <div class="inner-box">
                <div class="inner-item" @click="changeStatus(ele)">
                  修改运行状态
                </div>
              </div>
              <div slot="reference" class="cph">{{ ele.cph.slice(2) }}</div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="simulation-map-between right">
        <div class="title">
          副场
        </div>
        <div class="main-line">
          <div
            v-for="(item, index) in dispatchPlanList.slaverPlan"
            :key="index"
            class="list-car"
          >
            <i :class="getBusIcon(item.natureState)"></i>
            <el-popover placement="left" trigger="click">
              <div class="inner-box">
                <div class="inner-item" @click="sendMessage">
                  消息下发
                </div>
                <div class="inner-item" @click="sendPlanText($event, item.id)">
                  下发行车计划
                </div>
                <div class="inner-item" @click="changeDriverPop(item)">
                  调整计划
                </div>
                <div class="inner-item" @click="manualStart(item)">
                  手动发车
                </div>
                <div class="inner-item" @click="changeStatus(item)">
                  修改运行状态
                </div>
              </div>
              <div slot="reference" class="cph">{{ item.cph.slice(2) }}</div>
            </el-popover>
            <span :class="item.overTime ? 'overtime' : 'time'">{{
              item.beginTime
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部table -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content">
          <div class="top-content">
            <span style="margin-right:24px;">待发车列表</span>
            <el-button size="small" class="btn" @click="sendMessage"
              >消息下发</el-button
            >
            <el-button size="small" class="btn" @click="sendPlanText"
              >下发计划</el-button
            >
            <el-button size="small" class="btn" @click="changeDriver"
              >调整计划</el-button
            >
            <el-button
              size="small"
              class="btn"
              @click="() => (addShiftOrTripDia = true)"
              >增加计划</el-button
            >
            <el-button size="small" class="btn" @click="manualStartTogether"
              >手动发车</el-button
            >
            <el-button size="small" class="btn" @click="changeStatusSelect"
              >修改状态</el-button
            >
          </div>
          <el-table
            class="basicBox dispatchMonitoring"
            :data="startPlanDetailList"
            @selection-change="getCheckedData"
            height="calc(100vh - 47vh - 222px)"
            stripe
            :cell-style="{ color: '#000', fontSize: '16px' }"
            :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="checkboxT"
              disabled="true"
            ></el-table-column>
            <el-table-column prop="cph" label="车辆">
              <template slot-scope="scope">
                <span class="column-color" @click="showBusPlan(scope.row)">{{
                  scope.row.cph.slice(2)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="realityDriverName"
              label="驾驶员"
            ></el-table-column>
            <el-table-column prop="endTime" label="到达时间"></el-table-column>
            <el-table-column
              prop="beginTime"
              label="发车时间"
            ></el-table-column>
            <el-table-column prop="natureState" label="状态">
              <template slot-scope="scope">
                {{ scope.row.natureState | filterNatureState }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content">
          <el-tabs type="border-card">
            <el-tab-pane label="消息列表">
              <el-table
                class="basicBox dispatchMonitoring"
                :data="messageList"
                height="calc(100vh - 47vh - 237px)"
                stripe
                :cell-style="{ color: '#000', fontSize: '16px' }"
                :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
              >
                <el-table-column
                  type="index"
                  label="排序"
                  align="center"
                ></el-table-column>
                <el-table-column prop="cph" label="车辆">
                  <template slot-scope="scope">
                    <span style="color:#F48B41;">{{
                      scope.row.cph.slice(2)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="线路"
                  prop="companyName"
                ></el-table-column>
                <el-table-column
                  prop="content"
                  label="消息内容"
                  width="180"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column prop="type" label="消息类型"></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="时间"
                  width="180"
                ></el-table-column>
                <el-table-column prop="status" label="状态">
                  <template slot-scope="scope">
                    {{ scope.row.status | filterStatus }}
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="到离站信息">
              <el-table
                class="basicBox dispatchMonitoring"
                :data="arriveAndLeaveStationList"
                height="calc(100vh - 47vh - 237px)"
                stripe
                :cell-style="{ color: '#000', fontSize: '16px' }"
                :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
              >
                <el-table-column
                  type="index"
                  label="排序"
                  align="center"
                ></el-table-column>
                <el-table-column prop="cph" label="车辆">
                  <template slot-scope="scope">
                    <span style="color:#F48B41;">{{
                      scope.row.cph.slice(2)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="线路"
                  prop="companyName"
                ></el-table-column>
                <el-table-column prop="businessType" label="方向">
                  <template slot-scope="scope">
                    {{ scope.row.businessType === 1 ? "上行" : "下行" }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="gpsTime"
                  label="时间"
                  width="180"
                ></el-table-column>
                <el-table-column prop="arriveType" label="类型">
                  <template slot-scope="scope">
                    {{ scope.row.arriveType === 1 ? "到站" : "离站" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="stationName"
                  label="站点名称"
                  width="160"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  prop="realityDriverName"
                  label="当班司机"
                ></el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="车辆请求">
              <el-table
                class="basicBox dispatchMonitoring"
                :data="requestlist"
                height="calc(100vh - 47vh - 237px)"
                stripe
                :cell-style="{ color: '#000', fontSize: '16px' }"
                :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
              >
                <el-table-column
                  type="index"
                  label="排序"
                  align="center"
                ></el-table-column>
                <el-table-column prop="cph" label="车辆">
                  <template slot-scope="scope">
                    <span style="color:#F48B41;">{{
                      scope.row.cph.slice(2)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="线路"
                  prop="companyName"
                ></el-table-column>
                <el-table-column
                  prop="driverName"
                  label="司机"
                ></el-table-column>
                <el-table-column prop="eventId" label="消息内容" width="120">
                  <template slot-scope="scope">{{
                    natureOptions.find(t => t.dictCode == scope.row.eventId)
                      .dictValue
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="insertTime"
                  label="时间"
                  width="180"
                ></el-table-column>
                <el-table-column prop="opStats" label="状态" width="120">
                  <template slot-scope="scope">
                    {{ scope.row.opStats | filteropStats }}
                  </template>
                </el-table-column>
                <el-table-column prop="action" label="操作" width="120">
                  <template slot-scope="scope">
                    <div class="action">
                      <span
                        @click="agreeAndReject(scope.row.id, 1)"
                        class="agree"
                        v-if="
                          scope.row.opStats !== 1 && scope.row.opStats !== 2
                        "
                        >同意</span
                      >
                      <span
                        @click="agreeAndReject(scope.row.id, 2)"
                        class="reject"
                        v-if="
                          scope.row.opStats !== 1 && scope.row.opStats !== 2
                        "
                        >拒绝</span
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <!-- 弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="50%"
    >
      <sendMsg
        :lineId="lineCode"
        @closeDia="() => (dialogVisible = false)"
      ></sendMsg>
    </el-dialog>
    <!-- 调整计划 -->
    <el-dialog
      title="计划调整"
      :visible.sync="driverdialogVisible"
      v-if="driverdialogVisible"
      width="50%"
    >
      <driverPlan
        :updateform="updateform"
        :nowBusByline="nowBusByline"
        :nowDriverByline="nowDriverByline"
        @closeDia="() => (driverdialogVisible = false)"
      ></driverPlan>
    </el-dialog>
    <!-- 查看发车计划 -->
    <el-dialog
      title="单车发班表"
      :visible.sync="schedulingVisible"
      v-if="schedulingVisible"
      width="30%"
    >
      <schedulingPlan
        :busPlanList="busPlanList"
        :busCode="busCode"
        :banCiMoveType="banCiMoveType"
        @closeDia="() => (schedulingVisible = false)"
      ></schedulingPlan>
    </el-dialog>
    <!-- 添加班次/车次 -->
    <el-dialog
      title="添加班次/车次"
      :visible.sync="addShiftOrTripDia"
      width="50%"
      class="addShiftOrTripDia"
      @close="cancleAddShiftOrTripDia"
      @open="addShiftOrTripDia = true"
    >
      <addShiftOrTrip
        ref="addShiftOrTripRefs"
        @closeDia="() => (addShiftOrTripDia = false)"
      ></addShiftOrTrip>
    </el-dialog>
    <!-- 修改车辆状态 -->
    <el-dialog
      title="修改车辆状态"
      :visible.sync="changeStatusVisible"
      width="50%"
      class="addShiftOrTripDia"
    >
      <el-form
        :model="statusForm"
        ref="statusForm"
        label-width="102px"
        :rules="rules"
        class="demo-form dialog-form"
      >
        <el-form-item label="车次状态：" prop="state">
          <el-select v-model="statusForm.state" placeholder="选择车次状态">
            <el-option
              v-for="item in natureOptions"
              :label="item.dictValue"
              :value="Number(item.dictCode)"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          size="small"
          @click="changeStatusVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="saveStatus"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 车辆详情面板 -->
    <car-dialog
      v-if="dialogTableVisible"
      :titlex="plate"
      :id="plate"
      :area="area"
      :street="street"
      :checkObj="carDetailObj"
      @dialogVisible="() => (dialogTableVisible = false)"
    ></car-dialog>
  </div>
</template>
<script>
import {
  sendMessageList,
  queryAllLineList,
  queryNewEventsList,
  queryDispatchPlanList,
  queryArriveLeaveStationList,
  queryDispatchStatisticsAll,
  queryLineDetailByCompanyIds,
  queryBusPosition,
  queryStartPlanDetailList,
  sendPlanText,
  queryBusPlan,
  manualSendVehicle,
  manualModifyEvent,
  busDetailInfo
} from "@/api/lib/bus-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { updateEventReport } from "@/api/lib/refreshAlarm.js";
import sendMsg from "./components/sendMessage";
import driverPlan from "./components/driverPlan";
import schedulingPlan from "./components/schedulingPlan";
import addShiftOrTrip from "./components/addShiftOrTrip";
import dialog from "./components/dialog";

import moment from "moment";
export default {
  components: {
    sendMsg,
    driverPlan,
    schedulingPlan,
    addShiftOrTrip,
    "car-dialog": dialog
  },
  data() {
    return {
      dialogTableVisible: false, //显示车辆详情
      carDetailObj: {},
      area: "",
      street: "",
      color: "#66B1FF",
      plate: "", //当前选中车辆

      system: sessionStorage.getItem("system"),
      currentPage: 1,
      pageSize: 20,
      activeName: "",
      lineCode: 0,
      lineOptions: [],
      hintOptions: [
        {
          url: require("@/assets/images/busIcon/bus_map_nor.png"),
          val: "正常",
          state: 0
        },
        {
          url: require("@/assets/images/busIcon/bus_map_leave.png"),
          val: "离线",
          state: -1
        },
        {
          url: require("@/assets/images/busIcon/bus_map_err.png"),
          val: "维修",
          state: 6
        },
        {
          url: require("@/assets/images/busIcon/bus_map_aerate.png"),
          val: "加气",
          state: 4
        },
        {
          url: require("@/assets/images/busIcon/bus_map_offline.png"),
          val: "脱线",
          state: 999
        }
      ],
      messageList: [], //消息列表
      requestlist: [], //车辆请求列表
      arriveAndLeaveStationList: [], //到离站信息列表
      dispatchPlanList: [], //查询主副场即将发车的计划列表
      startPlanDetailList: [], //查询待发车列表
      busPlanList: [], //车辆发车计划
      busCode: "",
      banCiMoveType: 0,
      nowBusByline: [],
      nowDriverByline: [],
      dispatchStatisticsData: {
        online: 0,
        operate: 0,
        matching: 0,
        notOperateList: []
      }, //车辆调度统计
      fuToZhuOptions: [],
      zhuTofuOptions: [],
      downBus: [],
      upBus: [],
      natureOptions: [],
      ulWidth: 0,
      selfPage: false,
      dialogVisible: false, //下发消息
      driverdialogVisible: false, //调整计划
      schedulingVisible: false, //发车计划
      addShiftOrTripDia: false, //添加班次车次
      changeStatusVisible: false, //修改车次状态
      statusForm: {
        state: ""
      },
      title: "",
      changeStatusVehicleNo: "", //修改状态选中的车架号
      updateform: {
        lineId: null,
        oldCredentialsNo: null,
        oldVehicleNo: null,
        newCredentialsNo: null,
        newVehicleNo: null,
        workDay: null,
        classType: "1",
        updateType: null,
        type: "1",
        beginTime: null,
        natureState: null,
        remark: null,
        cheCiIds: [],
        moveType: ""
      },
      rules: {
        state: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      downStationSpace: 0,
      upStationSpace: 0,
      // 添加唯一标识,在组件销毁时区分不同组件的dropdown避免误删
      uniquelyIdentify: Number(
        Math.random()
          .toString()
          .substr(3, 5) + Date.now()
      ).toString(36),
      timer_info: null,
      timer_msg: null,
      timer_zf: null,
      timer_startplan: null,
      timer_arriveAndLeave: null,
      timer_dispath: null,
      timer_event: null
    };
  },
  filters: {
    filterStatus(val) {
      let data = "";
      if (val === 0) {
        data = "默认";
      } else if (val === 1) {
        data = "失败";
      } else if (val === 2) {
        data = "成功";
      } else {
        data = val;
      }
      return data;
    },
    filteropStats(item) {
      if (item == 0) {
        return "未处理";
      } else if (item == 1) {
        return "同意";
      } else if (item == 2) {
        return "拒绝";
      } else if (item == 3) {
        return "未处理(已阅)";
      }
    },
    filterNatureState(item) {
      if (item == 3) {
        return "加油";
      } else if (item == 2) {
        return "交班";
      } else if (item == 1) {
        return "排班";
      } else if (item == 4) {
        return "加气";
      } else if (item == 5) {
        return "充电";
      } else if (item == 6) {
        return "维修";
      } else if (item == 7) {
        return "包车";
      } else if (item == 128) {
        return "其他";
      } else if (item == 8) {
        return "恢复运营";
      } else {
        return "正常";
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer_info);
    clearInterval(this.timer_msg);
    clearInterval(this.timer_event);
    clearInterval(this.timer_zf);
    clearInterval(this.timer_startplan);
    clearInterval(this.timer_arriveAndLeave);
    clearInterval(this.timer_dispath);
  },
  destroyed() {
    this.destroyDropDowns();
  },
  watch: {
    fuToZhuOptions(val) {
      if (val.length > 1) {
        const width = this.ulWidth - 20;
        this.downStationSpace = width / (val.length - 1);
      }
    },
    zhuTofuOptions(val) {
      if (val.length > 1) {
        const width = this.ulWidth - 20; //减去图标盒子的宽度
        this.upStationSpace = width / (val.length - 1);
      }
    },
    lineCode(n, o) {
      this.getqueryDispatchPlan(); //查询主副场即将发车的计划列表-定时请求
      this.getqueryArriveLeaveStationList(); //到离站信息列表-定时请求
      this.getDispatchStatisticsAll(); //车辆调度统计-定时请求
      this.getqueryLineDetailByCompanyIds(); //线路详情
      this.getBusInfo(); //实时信息
      this.getqueryStartPlanDetailList(); //查询待发车列表-定时请求
      this.getSendMessageList(); //消息列表-定时请求--不跟随线路改变而改变
      this.getqueryNewEventsList(); //车辆请求-定时请求--不跟随线路改变而改变
    }
  },
  mounted() {
    this.ulWidth = this.$refs.upUl.clientWidth;
    this.getLineList(); //搜索框查询全部可选线路
    this.getDicts(); //字典
  },
  methods: {
    /* 车辆图标 */
    getBusIcon(status) {
      if (status == 0 || status == undefined) {
        return "bus-norm";
      }
      if (status == 1 || status == -1) {
        return "bus-offline";
      }
      if (status == 2) {
        return "bus-repair";
      }
      if (status == 3) {
        return "bus-aerate";
      }
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: "BUSLX" }).then(res => {
        if (res.code === 1000) {
          this.natureOptions = res.data.BUSLX;
        }
      });
    },
    //车辆请求
    getqueryNewEventsList() {
      if (this.timer_event) clearInterval(this.timer_event);
      const intervalIimerFn = () => {
        queryNewEventsList()
          .then(res => {
            this.requestlist = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_event = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },
    //车辆请求同意和拒绝操作
    agreeAndReject(id, opStats) {
      updateEventReport({ id, opStats })
        .then(res => {
          if (res.code === 1000) {
            this.$message.success("修改成功");
            this.getqueryNewEventsList();
          } else {
            this.$message.warning(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //禁用计划状态为删除的复选框
    checkboxT(row, index) {
      if (row.adjustStatus === "删除") {
        return false;
      } else {
        return true;
      }
    },
    changeDriverPop(val) {
      this.updateform.oldCredentialsNo = val.credentialsNo;
      this.updateform.oldVehicleNo = val.vehicleNo;
      this.updateform.beginTime = val.beginTime;
      this.nowBusByline = [{ cph: val.cph, vehicleNo: val.vehicleNo }];
      this.nowDriverByline = [
        { driverName: val.realityDriverName, credentialsNo: val.credentialsNo }
      ];
      this.updateform.cheCiIds = [val.id];
      this.changeDriver();
    },
    getCheckedData(val) {
      if (val.length == 1) {
        this.changeStatusVehicleNo = val[0].vehicleNo;
        this.updateform.oldCredentialsNo = val[0].credentialsNo;
        this.updateform.oldVehicleNo = val[0].vehicleNo;
        this.updateform.beginTime = val[0].beginTime;
        this.updateform.moveType = val[0].moveType;
        this.nowBusByline = [{ cph: val[0].cph, vehicleNo: val[0].vehicleNo }];
        this.nowDriverByline = [
          { driverName: val[0].driverName, credentialsNo: val[0].credentialsNo }
        ];
      }
      this.updateform.cheCiIds = val.map(ele => ele.id);
    },
    //修改状态
    changeStatus(item) {
      this.changeStatusVehicleNo = item.vehicleNo;
      this.changeStatusVisible = true;
    },
    changeStatusSelect() {
      if (this.updateform.cheCiIds.length == 1) {
        this.changeStatusVisible = true;
      } else {
        this.$message.warning("请勾选1个点进行修改状态！");
      }
    },
    //保存修改状态
    saveStatus() {
      this.$refs.statusForm.validate(valid => {
        if (valid) {
          const data = {
            eventId: this.statusForm.state,
            vehicleNo: this.changeStatusVehicleNo
          };
          manualModifyEvent(data)
            .then(res => {
              if (res.code === 1000) {
                this.changeStatusVisible = false;
                this.$message.success(res.msg);
              } else {
                this.$message.warning(res.msg);
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    },
    //手动发车
    manualStart(item) {
      const h = this.$createElement;
      this.$msgbox({
        title: "手动发车",
        type: "warning",
        message: h("p", null, [h("span", null, "确定手动发车吗 ？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          manualSendVehicle({
            vehicleNo: item.vehicleNo,
            moveType: item.moveType
          }).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消手动发车"
          });
        });
    },
    manualStartTogether() {
      if (this.updateform.cheCiIds.length == 1) {
        const data = {
          vehicleNo: this.updateform.oldVehicleNo,
          moveType: this.updateform.moveType
        };
        const h = this.$createElement;
        this.$msgbox({
          title: "手动发车",
          type: "warning",
          message: h("p", null, [h("span", null, "确定手动发车吗 ？")]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        })
          .then(() => {
            manualSendVehicle(data).then(res => {
              if (res.code === 1000) {
                this.$message.success(res.msg);
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消手动发车"
            });
          });
      } else {
        this.$message.warning("请勾选1个点手动发车！");
      }
    },
    //关闭计划弹窗
    cancleAddShiftOrTripDia() {
      this.$refs.addShiftOrTripRefs.cancleAddShiftOrTripDia();
    },
    // 打开调整计划弹窗
    changeDriver() {
      if (this.updateform.cheCiIds.length > 1) {
        this.updateform.type = "3";
      } else if (this.updateform.cheCiIds.length === 1) {
        this.updateform.type = "1";
      } else {
        this.$message.warning("请至少勾选1个点进行调整计划！");
        return;
      }
      this.updateform.lineId = this.editableTabsValue * 1;
      this.updateform.updateType = 0;
      this.updateform.newCredentialsNo = null;
      this.updateform.newVehicleNo = null;
      this.driverdialogVisible = true;
    },
    //下发行车计划
    sendPlanText(e, id) {
      if (this.updateform.cheCiIds.length > 0 || id) {
        this.$confirm("车辆将收到最新的行车计划，是否下发?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            sendPlanText({
              checiIds: id ? [id] : this.updateform.cheCiIds
            }).then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消下发行车计划"
            });
          });
      } else {
        this.$message.warning("请至少勾选1个点进行下发行车计划！");
      }
    },
    //发送消息
    sendMessage() {
      this.dialogVisible = true;
      this.title = "发送消息";
    },
    //消息列表
    getSendMessageList() {
      if (this.timer_msg) clearInterval(this.timer_msg);
      const intervalIimerFn = () => {
        const data = {
          system: this.system,
          pageSize: this.pageSize,
          currentPage: this.currentPage
        };
        sendMessageList(data)
          .then(res => {
            this.messageList = res.data.list || [];
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_msg = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },
    //搜索框查询全部可选线路
    getLineList() {
      queryAllLineList().then(res => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.lineOptions = res.data;
            this.lineCode = res.data[0].companyId;
            // this.activeName = res.data[0].companyId.toString();
            this.activeName = res.data[0].companyName;
          }
        }
      });
    },
    //切换线路
    changeLine(val) {
      let obj = null;
      obj = this.lineOptions.filter(item => item.companyId === val)[0];
      this.activeName = obj.companyName;
      //刷新下面的模拟地图数据
    },
    //查询主副场即将发车的计划列表
    getqueryDispatchPlan() {
      if (this.timer_zf) clearInterval(this.timer_zf);
      const intervalIimerFn = () => {
        queryDispatchPlanList({ companyId: this.lineCode })
          .then(res => {
            this.dispatchPlanList = res.data || [];
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_zf = setInterval(() => {
        intervalIimerFn();
      }, 10000);
    },
    //查询待发车列表
    getqueryStartPlanDetailList() {
      if (this.timer_startplan) clearInterval(this.timer_startplan);
      const intervalIimerFn = () => {
        queryStartPlanDetailList({ companyId: this.lineCode })
          .then(res => {
            this.startPlanDetailList = res.data || [];
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_startplan = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },
    //到离站信息列表
    getqueryArriveLeaveStationList() {
      if (this.timer_arriveAndLeave) clearInterval(this.timer_arriveAndLeave);
      const intervalIimerFn = () => {
        queryArriveLeaveStationList({ companyId: this.lineCode })
          .then(res => {
            this.arriveAndLeaveStationList = res.data || [];
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_arriveAndLeave = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },
    //车辆调度统计
    getDispatchStatisticsAll() {
      if (this.timer_dispath) clearInterval(this.timer_dispath);
      const intervalIimerFn = () => {
        queryDispatchStatisticsAll({ companyId: this.lineCode })
          .then(res => {
            if (res.code === 1000) {
              this.dispatchStatisticsData = res.data;
            } else {
              this.dispatchStatisticsData = {
                online: 0,
                operate: 0,
                matching: 0,
                notOperateList: []
              };
            }
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_dispath = setInterval(() => {
        intervalIimerFn();
      }, 20000);
    },
    //线路详情
    getqueryLineDetailByCompanyIds() {
      queryLineDetailByCompanyIds({ companyIds: [this.lineCode] })
        .then(res => {
          let data = res.data[0];
          if (data && data.downStationList) {
            this.zhuTofuOptions = data.downStationList; //下行
          } else {
            this.zhuTofuOptions = [];
          }
          if (data && data.upStationList) {
            this.fuToZhuOptions = data.upStationList.reverse(); //上行
          } else {
            this.fuToZhuOptions = [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    /* 获取车辆信息 */
    getBusInfo() {
      let self = this;
      if (this.timer_info) clearInterval(this.timer_info);
      let timerFn = function() {
        queryBusPosition({ companyId: self.lineCode }).then(res => {
          if (res.code === 1000) {
            // businessType => 1上行（副到主）2下行（主到副）
            const down = res.data.filter(
              item => item.stationInfo && item.stationInfo.businessType == "2"
            );
            let downArr = [];
            down.forEach(item => {
              if (downArr.length == 0) {
                downArr.push({
                  stationNo: item.stationInfo.stationNo,
                  arriveType: item.stationInfo.arriveType,
                  busCodes: [
                    {
                      busCode: item.bus.cph,
                      color: item.bus.vehicleColor,
                      status: item.status,
                      vehicleNo: item.vehicleNo
                    }
                  ]
                });
              } else {
                let flag = false;
                downArr.forEach(arr => {
                  if (
                    item.stationInfo.stationNo == arr.stationNo &&
                    item.stationInfo.arriveType == arr.arriveType
                  ) {
                    flag = true;
                    arr.busCodes = [
                      ...arr.busCodes,
                      {
                        busCode: item.bus.cph,
                        color: item.bus.vehicleColor,
                        status: item.status,
                        vehicleNo: item.vehicleNo
                      }
                    ];
                  }
                });
                if (!flag) {
                  downArr.push({
                    stationNo: item.stationInfo.stationNo,
                    arriveType: item.stationInfo.arriveType,
                    busCodes: [
                      {
                        busCode: item.bus.cph,
                        color: item.bus.vehicleColor,
                        status: item.status,
                        vehicleNo: item.vehicleNo
                      }
                    ]
                  });
                }
              }
            });
            self.downBus = downArr;

            // 上行
            const up = res.data.filter(
              item => item.stationInfo && item.stationInfo.businessType == "1"
            );

            let upArr = [];
            up.forEach(item => {
              if (upArr.length == 0) {
                upArr.push({
                  stationNo: item.stationInfo.stationNo,
                  arriveType: item.stationInfo.arriveType,
                  busCodes: [
                    {
                      busCode: item.bus.cph,
                      color: item.bus.vehicleColor,
                      status: item.status,
                      vehicleNo: item.vehicleNo
                    }
                  ]
                });
              } else {
                let flag = false;
                upArr.forEach(arr => {
                  if (
                    item.stationInfo.stationNo == arr.stationNo &&
                    item.stationInfo.arriveType == arr.arriveType
                  ) {
                    flag = true;
                    arr.busCodes = [
                      ...arr.busCodes,
                      {
                        busCode: item.bus.cph,
                        color: item.bus.vehicleColor,
                        status: item.status,
                        vehicleNo: item.vehicleNo
                      }
                    ];
                  }
                });
                if (!flag) {
                  upArr.push({
                    stationNo: item.stationInfo.stationNo,
                    arriveType: item.stationInfo.arriveType,
                    busCodes: [
                      {
                        busCode: item.bus.cph,
                        color: item.bus.vehicleColor,
                        status: item.status,
                        vehicleNo: item.vehicleNo
                      }
                    ]
                  });
                }
              }
            });
            self.upBus = upArr;
            setTimeout(() => {
              self.drawDropdowns();
            }, 200);
          } else {
            self.downBus = [];
            self.upBus = [];
            self.destroyDropDowns();
          }
        });
      };
      //立即执行一次
      timerFn();

      //设置定时器
      self.timer_info = setInterval(() => {
        timerFn();
      }, 10000);
    },
    // 点击车牌号显示弹窗
    onCarClick(event) {
      let dom = event.target;
      if (dom.getAttribute("vehicleNo"))
        this.showCarDetail(dom.getAttribute("vehicleNo"));
    },
    // 点击车牌号显示弹窗
    showCarDetail(vehicleNo) {
      busDetailInfo({ vehicleNo: vehicleNo }).then(res => {
        if (res.data) {
          this.plate = res.data.cph;
          this.carDetailObj = res.data;
          this.dialogTableVisible = true;
        }
      });
      this.dialogTableVisible = true;
    },
    // 点击dropdown
    onDropDownClick(event) {
      let dom = event.target.parentNode.parentNode.parentNode.children[1];
      dom.style.display =
        dom.style.display == "" || dom.style.display == "flex"
          ? "none"
          : "flex";
      this.drawDropdowns();
    },
    // 绘制dropdown
    drawDropdowns() {
      this.destroyDropDowns();
      // 上行
      const down = this.$refs.down.children;
      Array.from(down).forEach(e => {
        let h = e.children[0].children[0].clientHeight;
        let w = e.children[0].children[0].clientWidth;
        let top =
          e.getBoundingClientRect().top + document.documentElement.scrollTop;
        let left = e.getBoundingClientRect().left;
        let dd = e.children[1].cloneNode(true);
        dd.style.top = `${top - e.children[1].clientHeight - 8}px`;
        dd.style.left = `${left + 0.5 * w - 1.8 * e.children[1].clientWidth}px`;
        dd.setAttribute("uniquelyIdentify", this.uniquelyIdentify);
        dd.addEventListener("click", this.onCarClick);
        document.body.appendChild(dd);
      });
      // 下行
      const up = this.$refs.up.children;
      Array.from(up).forEach(e => {
        let h = e.children[0].children[0].clientHeight;
        let w = e.children[0].children[0].clientWidth;
        let top =
          e.getBoundingClientRect().top + document.documentElement.scrollTop;
        let left = e.getBoundingClientRect().left;
        let dd = e.children[1].cloneNode(true);
        dd.style.top = `${top + h + 10}px`; //车牌对应图标的距离
        dd.style.left = `${left + 0.5 * w - 1.8 * e.children[1].clientWidth}px`; //车牌对应图标的距离
        dd.setAttribute("uniquelyIdentify", this.uniquelyIdentify);
        dd.addEventListener("click", this.onCarClick);
        document.body.appendChild(dd);
      });
    },
    // 销毁dropdown
    destroyDropDowns() {
      const destroyNodes = document.querySelectorAll(
        'ul[uniquelyIdentify="' + this.uniquelyIdentify + '"]'
      );
      if (destroyNodes.length != 0) {
        Array.from(destroyNodes).forEach(e => {
          document.body.removeChild(e);
        });
      }
    },
    //查看车辆计划
    showBusPlan(row) {
      const data = {
        vehicleNo: row.vehicleNo,
        planType: 2,
        workDay: moment(new Date()).format("YYYY-MM-DD")
      };
      this.busPlanList = [];
      queryBusPlan(data)
        .then(res => {
          this.busCode = res.data.cph;
          this.banCiMoveType = res.data.banCiMoveType;
          res.data.planList.forEach(item => {
            if (!(item.down == "" && item.up == "")) {
              this.busPlanList.push(item);
            }
          });
          this.schedulingVisible = true;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.theme-project-bus {
  .RealTimeScheduling {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    font-size: 14px;
  }
}
.inner-box {
  text-align: center;
  .inner-item {
    line-height: 28px;
    cursor: pointer;
  }
}
.column-color {
  color: #f48b41;
  cursor: pointer;
}
/deep/.el-table {
  td,
  th {
    font-size: 12px !important;
  }
  .cell {
    line-height: 36px;
  }
}
/deep/.el-main {
  padding: 10px;
}
/deep/.el-table:not(.no-blank) .el-table__empty-text {
  height: 0;
}
/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
.RealTimeScheduling {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 8px 20px;
  box-sizing: border-box;
  // overflow-y:auto;
  .top-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .simulation-map-box {
    font-size: 12px;
    margin: 7px 0 18px;
    // height: 60vh;
    height: 49vh;
    display: flex;
    .simulation-map-between {
      width: 140px;
      height: 40px;
      background: #f7f8f9;
      .title {
        line-height: 40px;
        text-align: center;
      }
      .main-line {
        padding-left: 15px;
        line-height: 34px;
        font-size: 12px;
        .list-car {
          display: flex;
          align-items: center;
          i {
            width: 16px;
            height: 16px;
          }
        }
        .cph {
          margin: 0 12px 0 4px;
          cursor: pointer;
          display: inline-block;
          width: 40%;
        }
        .cph:hover {
          color: #f48b41;
        }
        .cph:focus {
          color: #f48b41;
        }
        .time {
          color: #969799;
        }
        .overtime {
          color: #f74242;
        }
      }
    }
    .left {
      border: 1px solid #9ab6fb;
      height: 100%;
      .title {
        background: #9ab6fb;
      }
    }
    .right {
      border: 1px solid #ffb658;
      height: 100%;
      .title {
        background: #f78d42;
      }
    }
    .simulation-map-center {
      flex: 1;
      background: #f2f3f4;
      border: 1px solid #dcdedf;
      .center-logo {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: flex-end;
          .item {
            display: flex;
            align-items: center;

            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            font-size: 12px;
            margin-right: 10px;
            color: #333;
          }
        }
        .online {
          flex: 1;
          padding-left: 20px;
          span {
            color: #f48b41;
          }
        }
        .name {
          width: 140px;
          line-height: 40px;
          background: rgba(247, 141, 66, 0.2);
          border-radius: 8px;
          text-align: center;
          border: 1px solid rgba(244, 139, 65, 0.2);
        }
      }
      .map-notOperateList {
        display: flex;
        padding-left: 12px;
        background: rgba(220, 222, 223, 0.3);
        line-height: 26px;
        .other-cars {
          margin-left: 12px;
          cursor: pointer;
        }
      }
      .simulation-map {
        height: calc(100% - 77px);

        .up,
        .down {
          height: 50%;
          position: relative;
          .line-center {
            height: 4px;
            background: #c4d2f3;
            position: absolute;
            width: 100%;
            top: 48%;
          }
          ul {
            width: 88%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            height: 50%;
            position: relative;
            top: 46%;
            li {
              width: 20px;
              font-size: 12px;
              display: inline-block;
              position: relative;
              display: flex;
              flex-direction: column;
              color: #666666;
              i {
                z-index: 9;
                display: inline-block;
                content: "";
                background-color: #fff;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                border: 2px solid #afc3f2;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
              }
              span {
                height: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-orientation: upright;
                white-space: nowrap;
                writing-mode: vertical-lr;
                position: absolute;
                width: 16px;
                cursor: pointer;
              }
            }
          }
        }
        .up {
          ul {
            left: 6%;
          }
          li:nth-child(odd) {
            span {
              transform: rotate(45deg) translate(-130%, 31%);
            }
          }
          li:nth-child(even) {
            transform: rotate(180deg) translateY(-12px);
            top: -100%;
            span {
              transform: rotate(137deg) translate(-156%, -35%);
              text-align: right;
            }
          }
        }
        .down {
          ul {
            left: 6%;
          }

          li:nth-child(odd) {
            span {
              transform: rotate(-45deg) translate(150%, 31%);
            }
          }
          li:nth-child(even) {
            transform: rotate(180deg) translateY(-12px);
            top: -100%;
            span {
              // transform:rotate(45deg) translateX(-143%);
              transform: rotate(-137deg) translate(141%, -30%);
              text-align: right;
            }
          }
        }
      }
    }
  }
  .cus-dropdown {
    display: inline-block;
    // position: relative;
    position: absolute;
    .dropdown-list {
      visibility: hidden;
      width: 4vw;
      height: auto;
      padding: 0.5vh 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background: #fff;
      border-radius: 4px;
      z-index: 8;
      li {
        width: 4vw;
        text-align: center;
        margin-bottom: 0.5vh;
        cursor: pointer;
      }
    }
    .dropdown-up {
      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 0.5vh solid transparent;
        border-right: 0.5vh solid transparent;
        border-top: 0.75vh solid #ffffff;
        bottom: -0.75vh;
      }
    }
    .dropdown-down {
      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 0.5vh solid transparent;
        border-right: 0.5vh solid transparent;
        border-bottom: 0.75vh solid #ffffff;
        top: -0.75vh;
      }
    }
  }
  .bus-norm {
    display: inline-block;
    content: "";
    height: 20px;
    width: 20px;
    background: url("../../../../assets/images/busIcon/bus_map_nor.png")
      no-repeat;
    background-size: 100%;
  }

  .bus-offline {
    display: inline-block;
    content: "";
    height: 20px;
    width: 20px;
    // background: url("../../../../assets/images/busIcon/bus_map_nor.png") no-repeat;
    background: url("../../../../assets/images/busIcon/bus_map_leave.png")
      no-repeat;
    background-size: 100%;
  }

  .bus-aerate {
    display: inline-block;
    content: "";
    height: 20px;
    width: 20px;
    background: url("../../../../assets/images/busIcon/bus_map_aerate.png")
      no-repeat;
    background-size: 100%;
  }

  .bus-repair {
    display: inline-block;
    content: "";
    height: 20px;
    width: 20px;
    background: url("../../../../assets/images/busIcon/bus_map_err.png")
      no-repeat;
    background-size: 100%;
  }

  div[class*="bus-"] {
    width: 100%;
    height: 8vh;
    // background: rgba($color: #4b8dfd, $alpha: 0.1);
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    .line {
      flex: 1;
      height: 24px;
      position: relative;
    }
  }
  // .bus-down {
  //   top: -9vh;
  //   left: -0.25%;
  //   align-items: flex-end;
  // }

  // .bus-up {
  //   bottom: -6px;
  //   left: -0.5%;
  //   border: 1px solid #000;
  //   // position: absolute;
  // }

  //车辆
  .bus-down {
    width: 60px;
    position: absolute;
    text-align: center;
    z-index: 99;
    bottom: 2.7vh;
    font-size: 12px;
  }
  .bus-up {
    width: 60px;
    position: absolute;
    text-align: center;
    z-index: 99;
    top: -0.5vh;
    left: -0.25%;
    font-size: 12px;
  }
  .grid-content {
    .top-content {
      .btn {
        background: #ebedf0;
        line-height: 24px;
        padding: 0 6px;
      }
    }
    .basicBox {
      margin-top: 10px;
    }
    .action {
      // display: flex;
      .agree {
        cursor: pointer;
        color: #336ffe;
      }
      .reject {
        cursor: pointer;
        color: #f74242;
        margin-left: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.dropdown-list {
  width: 3vw;
  height: auto;
  padding: 0.5vh 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgba($color: #4b8dfd, $alpha: 0.2);
  border-radius: 4px;
  // z-index: 2500;
  // border: 1px solid rgb(108, 50, 175);
  li {
    // width: 3vw;
    text-align: center;
    margin-bottom: 0.5vh;
    font-size: 12px;
    cursor: pointer;
  }
}
.dropdown-up {
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 0.5vh solid transparent;
    border-right: 0.5vh solid transparent;
    border-top: 0.75vh solid rgba($color: #4b8dfd, $alpha: 0.2);
    bottom: -0.75vh;
  }
}

.dropdown-down {
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 0.5vh solid transparent;
    border-right: 0.5vh solid transparent;
    border-bottom: 0.75vh solid rgba($color: #4b8dfd, $alpha: 0.2);
    top: -0.75vh;
  }
}
</style>
